<template>
    <ion-page class="ion-page page-profile-customer-edit">
        <ion-content>
            <div class="container">

                <div class="header-wrapper">
                    <ion-icon class="back-button" :icon="arrowBackOutline" @click="goBack()"></ion-icon>
                    <h1 class="title">Môj profil</h1>
                </div>

<!--                <ion-row class="notification-types-wrapper">-->
<!--                    <ion-col size="6" class="notifications-title">-->
<!--                        Spôsob notifikácii:-->
<!--                    </ion-col>-->
<!--                    <ion-col size="6" class="notification-types-col">-->
<!--                        <ion-list mode="ios" lines="none" class="notification-types">-->
<!--                            <ion-item class="type-item">-->
<!--                                <ion-label class="label">SMS</ion-label>-->
<!--                                <ion-checkbox-->
<!--                                    slot="start"-->
<!--                                    class="checkbox"-->
<!--                                ></ion-checkbox>-->
<!--    &lt;!&ndash;                                v-model="registerAsCompany"&ndash;&gt;-->
<!--    &lt;!&ndash;                                @update:modelValue="checkRegisterAsCompany"&ndash;&gt;-->
<!--                            </ion-item>-->

<!--                            <ion-item class="type-item">-->
<!--                                <ion-label class="label">Push</ion-label>-->
<!--                                <ion-checkbox-->
<!--                                    slot="start"-->
<!--                                    class="checkbox"-->
<!--                                ></ion-checkbox>-->
<!--                            </ion-item>-->
<!--                        </ion-list>-->
<!--                    </ion-col>-->
<!--                </ion-row>-->

                <ion-list mode="ios" class="main-form-container">
                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Meno a priezvisko:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Adresa:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Telefón:</ion-label>
                        <ion-input type="tel" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">E-mail:</ion-label>
                        <ion-input type="email" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Dátum narodenia:</ion-label>
                        <ion-input type="date" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-row class="photo-input-wrapper">
                            <ion-col size="6">
                                <ion-label class="label" position="stacked">
                                    Profilová fotografia:
                                </ion-label>
                                <ion-button class="btn btn-outline" @click="openInputFile">
                                    <span class="btn-content">
                                        <span class="btn-content__label">Zmeniť</span>
                                        <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                                    </span>
                                </ion-button>
                                <input
                                    type="file"
                                    class="profile-photo-input"
                                    accept=".jpg, .jpeg, .png"
                                    @input="onImageUpload"
                                    ref="profile_photo_input"
                                />
                            </ion-col>
                            <ion-col size="6" class="photo-wrapper">
                                <img
                                    class="photo-preview"
                                    v-if="profileData.profilePhoto.preview"
                                    :src="profileData.profilePhoto.preview"
                                    alt=" "
                                />
                            </ion-col>
                        </ion-row>
                    </ion-item>

                    <span class="break-line"></span>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Názov spoločnosti:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">IČO:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">DIČ:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <span class="break-line"></span>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Pôvodné heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Nové heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Zopakujte heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>
                </ion-list>

                <div class="consents-wrapper">
                    <ion-row mode="ios" class="part-item-clickable">
                        <ion-col size="2" class="checkbox-col">
                            <ion-checkbox class="checkbox"></ion-checkbox>
                        </ion-col>
                        <ion-col size="10">
                            Súhlasím so <a href="">spracovaním osobných údajov</a>
                        </ion-col>
                    </ion-row>

                    <ion-row mode="ios" class="part-item-clickable">
                        <ion-col size="2" class="checkbox-col">
                            <ion-checkbox class="checkbox"></ion-checkbox>
                        </ion-col>
                        <ion-col size="10">
                            Súhlasím so <a href="">všeobecnými obchodnými podmienkami služby Carsen CUSTOMER</a>
                        </ion-col>
                    </ion-row>
                </div>

                <ion-button
                    mode="ios"
                    class="btn btn-primary"
                    @click="updateUser"
                    :class="{ 'btn-loading': loaders.updateUser }"
                >
                    <span v-if="!loaders.updateUser" class="btn-content">
                        <span class="btn-content__label">Aktualizovať profil</span>
                        <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                    </span>
                    <ion-spinner v-else></ion-spinner>
                </ion-button>

            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonList, IonItem, IonLabel, IonCheckbox, IonRow, IonCol } from "@ionic/vue"
import { chevronForwardOutline, arrowBackOutline } from "ionicons/icons"

export default {
    name: "PageCustomerProfileEdit",
    components: {
        IonList, IonItem, IonLabel, IonCheckbox, IonRow, IonCol
    },
    data() {
        return {
            chevronForwardOutline, arrowBackOutline,

            profileData: {
                profilePhoto: {
                    source: null,
                    preview: null,
                }
            },

            loaders: {
                updateUser: false,
            }
        }
    },
    methods: {
        openInputFile() {
            this.$refs.profile_photo_input.click()
        },
        onImageUpload(event) {
            this.profileData.profilePhoto.source = event.target.files[0];
            this.profileData.profilePhoto.preview = URL.createObjectURL(event.target.files[0]);
        },
        updateUser() {
            this.loaders.updateUser = !this.loaders.updateUser
        }
    },
}
</script>

<style lang="scss" scoped>
.page-profile-customer-edit::v-deep {
    .title {
        text-align: center;
        margin-top: 20px;
    }

    .notification-types-wrapper {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        > ion-col {
            margin: 0;
            padding: 0;
        }

        .notifications-title {
            font-size: 18px;
            font-weight: 600;
        }

        .notification-types-col {
            display: flex;
            align-items: center;
            justify-content: center;

            .notification-types {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;

                .type-item {
                    margin-right: 5px;

                    .label {
                        font-size: 13px;
                    }

                    .checkbox {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>