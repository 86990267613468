<template>
    <div class="trip-stops" @click="openOrderDetail">
        <ion-row class="travel-info-wrapper from">
            <ion-col size="1" class="travel-icon">
                <img class="icon" :src="iconCircle" alt=" " />
                <span class="background-line"></span>
            </ion-col>
            <ion-col size="11" class="travel-content">
                <h3>Letisko Viedeň-Schwechat</h3>
            </ion-col>
        </ion-row>

        <ion-row class="travel-info-wrapper stops">
            <ion-col size="1" class="travel-icon">
                <img class="icon" :src="addCircle" alt=" " />
                <span class="background-line"></span>
            </ion-col>
            <ion-col size="11" class="travel-content">
                2 medzi zastávky
            </ion-col>
        </ion-row>

        <ion-row class="travel-info-wrapper to">
            <ion-col size="1" class="travel-icon">
                <img class="icon" :src="locationSharp" alt=" " />
                <span class="background-line"></span>
            </ion-col>
            <ion-col size="11" class="travel-content">
                Ružová dolina 29, Bratislava
            </ion-col>
        </ion-row>
    </div>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue';
import iconCircle from "@/assets/order/iconCircle.svg"
import addCircle from "@/assets/order/addCircle.svg"
import locationSharp from "@/assets/order/locationSharp.svg"
import phone from "@/assets/icons/phone.svg"
import { personOutline } from "ionicons/icons"

export default {
    name: "TripStops",
    components: {
        IonRow,
        IonCol
    },
    data() {
        return {
            iconCircle, addCircle, locationSharp, phone,
            personOutline,
        }
    },
    methods: {
        openOrderDetail() {
            const orderID = 'test-order-id'
            this.$emit('open-order-detail', orderID)
        }
    },
}
</script>

<style lang="scss" scoped>
.trip-stops::v-deep {
    margin: 15px 0;

    .travel-info-wrapper {
        margin: 0;
        padding: 0;

        ion-col {
            margin: 0;
            padding: 0;
        }

        ion-col:first-of-type {
            margin-left: -5px;
            margin-right: 5px;
            max-width: 20px !important;
        }

        &:first-of-type {
            .travel-icon .background-line {
                height: 50%;
                bottom: 0;
            }
        }

        &:last-of-type {
            .travel-icon .background-line {
                height: 50%;
                top: 0;
            }
        }

        .travel-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                z-index: 1;
            }

            .background-line {
                display: block;
                position: absolute;
                width: 1px;
                height: 100%;
                z-index: 0;
                background-color: var(--ion-color-gray-border);
            }
        }

        .travel-content {
            padding: 3px 0;
        }

        &.from {
            //
        }

        &.stops {
            font-size: 13px;
            font-weight: 600;

            .icon {
                font-size: 14px;
                color: var(--ion-custom-color-green);
            }
        }

        &.to {
            .icon {
                color: var(--ion-color-primary);
            }
        }
    }
}
</style>