<template>
    <div class="search-input-wrapper">
        <ion-input
            type="text"
            class="search-input"
            @ionChange="changeInput"
        ></ion-input>
        <img
            class="icon"
            :src="magnifyingGlass"
            alt=" "
        />
    </div>
</template>

<script>
import magnifyingGlass from "@/assets/icons/magnifyingGlass.svg"

export default {
    name: "SearchInput",
    data() {
        return {
            magnifyingGlass,
        }
    },
    methods: {
        changeInput(event) {
            this.$emit('change-input', event.target.value)
        },
    },
}
</script>

<style lang="scss" scoped>
.search-input-wrapper::v-deep {
    position: relative;
    padding: 15px 0;

    .search-input {
        height: 55px;
        --padding-end: 20px;
        --padding-start: 50px;
        background: white;
        border-radius: 28px;
        border: 1px solid var(--ion-color-gray-border-light);
        box-shadow: inset 0 3px 6px var(--ion-color-gray-shadow);
        z-index: 0;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0, -50%);
        color: var(--ion-color-primary);
        z-index: 1;
    }
}
</style>