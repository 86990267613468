<template>
    <div class="car-number">
        {{ carNumber }}
    </div>
</template>

<script>
export default {
    name: "CarNumber",
    props: {
        /**
         * Car number
         * @example PP766EF
         */
        carNumber: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.car-number::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-width: 85px;
    height: 28px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    border-radius: 3px;
    border: 1px solid var(--ion-color-gray-border);
}
</style>