<template>
    <div class="order-list-card">
        <div class="travel-detail-container">
            <PassengersCount :count="6" />
            <OrderTime :time="'21:00'" />
            <CarNumber :car-number="'PP-766EF'" />
            <OrderTag
                v-for="(tag, tagKey, index) in testTagsData" :key="tagKey"
                :tag-id="index + '-' + tagUUID"
                :tag-data="tag"
            />
        </div>

        <TripStops />

        <div class="customer-info-container">
            <div class="customer-info">
                <div class="photo-wrapper">
                    <img class="photo" :src="order.user.image" alt=" " />
                </div>

                <div class="info-wrapper">
                    <p class="name-info">{{ order.user.company }}</p>
                    <p class="name-info">{{ order.user.name }}</p>
                </div>
            </div>
            <div class="customer-phone">
                <a href="tel:+421905123123">
                    <img class="icon" :src="phone" alt=" " />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CarNumber from "@/components/general/elements/CarNumber";
import OrderTag from "@/components/general/elements/OrderTag";
import OrderTime from "@/components/general/elements/OrderTime";
import {v4 as uuidv4} from 'uuid';
import phone from "@/assets/icons/phone.svg"
import TripStops from "@/components/general/elements/TripStops";
import PassengersCount from "@/components/general/elements/PassengersCount";

export default {
    name: "OrderListCard",
    components: {
        CarNumber,
        PassengersCount,
        TripStops,
        OrderTime,
        OrderTag,
    },
    props: {
        /**
         * Order key
         * @example ''
         */
        orderKey: {
            type: String,
            default: ''
        },
        /**
         * Order data
         * @example {}
         */
        order: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            phone,

            testTagsData: {
                1: {
                    type: 'tiket',
                    text: 'sdfasdf asdf asdf asdfasdf a'
                },
                2: {
                    type: 'repeat',
                    text: 'kmlhm nghm lf hhg fg fgh gh '
                }
            }
        }
    },
    computed: {
        /**
         * Generate unique uuid for tag (every tag must have a specific ID for ion-popover)
         * @return {string}
         * @example '38cf37a1-2825-4aca-a7ba-d269f0ffb2f2'
         */
        tagUUID() {
            return uuidv4()
        }
    }
}
</script>

<style lang="scss" scoped>
.order-list-card::v-deep {
    padding: 10px 20px 20px;

    &:nth-child(2n) {
        background: var(--ion-color-gray-content);
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--ion-color-gray-border);
    }

    .travel-detail-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        > div {
            margin-top: 10px;
            margin-right: 8px;
        }
    }

    .customer-info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background: white;
        box-shadow: 0 6px 12px var(--ion-color-gray-shadow);

        .customer-info {
            display: flex;
            align-items: center;

            .photo-wrapper {
                width: 50px;
                height: 50px;
                aspect-ratio: 1/1;

                .photo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .info-wrapper {
                margin-left: 15px;
                padding-right: 10px;

                .name-info {
                    font-size: 13px;

                    white-space: normal;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .customer-phone {
            margin-right: 20px;

            .icon {
                min-width: 20px;
                aspect-ratio: 1/1;
            }
        }
    }
}
</style>