<template>
    <ion-page class="ion-page page-order-way-there">
        <ion-content>
            PageOrderWayThere
        </ion-content>
    </ion-page>
</template>

<script>
export default {
    name: "PageOrderWayThere",
}
</script>

<style lang="scss" scoped>
.page-order-way-there::v-deep {
    //
}
</style>