<template>
    <ion-page class="ion-page page-dashboard">
        <ion-content>
            <div class="container">
                <MainHeader />
            </div>

            <h2 class="list-title set-size">Plánované zakázky</h2>

            <div class="container-wrapper">
                <OrderList />
            </div>

        </ion-content>
    </ion-page>
</template>

<script>
import MainHeader from "@/components/main/MainHeader";
import OrderList from "@/components/driver/OrderList";

export default {
    name: "PageDriverDashboard",
    components: {
        OrderList,
        MainHeader
    },
}
</script>

<style lang="scss" scoped>
.page-dashboard::v-deep {
    .list-title {
        margin: 20px;
        text-align: center;
    }
}
</style>