<template>
    <div class="chat-messages">
        <div class="chat-header">
            <img class="icon" :src="chat" alt=" " />
            <h2 class="chat-title">Chat so zákazníkom</h2>
        </div>

        <div class="chat-new-message">
            <ion-textarea
                rows="1"
                :autoGrow="true"
                class="new-message"
                v-model="newComment"
            ></ion-textarea>
            <ion-icon
                @click="postNewComment"
                :icon="chevronForwardCircle"
                class="icon"
            ></ion-icon>
        </div>

        <div class="conversation">
            <ChatMessageCard message-type="driver" />
            <ChatMessageCard message-type="driver" />
            <ChatMessageCard message-type="customer" />
            <ChatMessageCard message-type="driver" />
            <ChatMessageCard message-type="customer" />
            <ChatMessageCard message-type="customer" />
        </div>
    </div>
</template>

<script>
import chat from "@/assets/order/chat.svg"
import ChatMessageCard from "@/components/general/ChatMessageCard";
import { chevronForwardCircle } from "ionicons/icons"
import { IonTextarea } from "@ionic/vue"

export default {
    name: "ChatMessages",
    components: {
        IonTextarea,
        ChatMessageCard
    },
    data() {
        return {
            chat,
            chevronForwardCircle,

            newComment: '',
        }
    },
    methods: {
        postNewComment() {
            this.showToast(this.newComment, 'toast-danger')
        }
    },
}
</script>

<style lang="scss" scoped>
.chat-messages::v-deep {
    margin: 20px 0;

    .chat-header {
        display: flex;

        .chat-title {
            margin-left: 10px;
            font-size: 20px;
        }
    }

    .chat-new-message {
        position: relative;
        margin: 20px 0;

        .new-message {
            margin: 0;
            --padding-start: 20px;
            --padding-end: 45px;
            min-height: 44px;
            max-height: 500px;
            border-radius: 25px;
            border: 1px solid var(--ion-color-gray-border);
            z-index: 0;
        }

        .icon {
            position: absolute;
            font-size: 40px;
            top: 50%;
            right: 5px;
            transform: translate(0, -50%);
            color: var(--ion-color-primary);
            z-index: 1;
        }
    }
}
</style>