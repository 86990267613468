<template>
    <div class="order-detail-stop-card">
        <ion-row class="destination-wrapper">
            <ion-col size="1" class="icon-wrapper">
                <img class="icon icon-start" :src="iconCircle" alt=" " />
                <img class="icon icon-stop" :src="addCircle" alt=" " />
                <img class="icon icon-end" :src="locationSharp" alt=" " />
                <span class="background-line"></span>
            </ion-col>
            <ion-col size="8" class="destination">
                <p class="destination-text">Chocolate cake bis</p>
            </ion-col>
            <ion-col size="3" class="time-wrapper">
                <OrderTime :time="'12:00'" />
            </ion-col>
        </ion-row>

        <ion-row class="passenger-wrapper">
            <ion-col size="1"></ion-col>
            <ion-col size="2" class="count-wrapper">
                <PassengersCount :passengers-count="3" />
            </ion-col>
            <ion-col :size="allowCall ? 7 : 9" class="passenger-info">
                <div class="image-wrapper">
                    <img class="image" :src="testImageUlr" alt=" " />
                </div>
                <div class="info-wrapper">
                    <p class="info-text">Alojz Novak s.r.o.</p>
                    <p class="info-text">Alojz Novak</p>
                </div>
            </ion-col>
            <ion-col v-if="allowCall" size="2" class="passenger-phone">
                <a href="tel:+421905123123">
                    <img :src="phone" alt=" " />
                </a>
            </ion-col>
        </ion-row>
    </div>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue';
import OrderTime from "@/components/general/elements/OrderTime";
import iconCircle from "@/assets/order/iconCircle.svg"
import addCircle from "@/assets/order/addCircle.svg"
import locationSharp from "@/assets/order/locationSharp.svg"
import phone from "@/assets/icons/phone.svg"
import PassengersCount from "@/components/general/elements/PassengersCount";

export default {
    name: "OrderDetailStopCard",
    components: {
        PassengersCount,
        OrderTime,
        IonRow, IonCol
    },
    props: {
        /**
         * Define whether or not to display the phone icon
         * @example true - show icon
         * @example false - do not show icon
         */
        allowCall: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            iconCircle, addCircle, locationSharp, phone,

            testImageUlr: 'https://www.researchgate.net/profile/Hanhe-Lin/publication/344400221/figure/fig1/AS:940579932348417@1601263139233/SAN-and-Tarsier-on-the-baboon-image-from-Set-14-The-image-generated-by-SAN-is-visibly.ppm',
        }
    },
}
</script>

<style lang="scss" scoped>
.order-detail-stop-card::v-deep {
    margin: 20px 0;
    padding: 10px 5px;
    box-shadow: 0 6px 12px var(--ion-color-gray-shadow);

    &:first-of-type, &:last-of-type {
        background: var(--ion-color-gray-border-light);

        .destination {
            .destination-text {
                font-weight: bold;
            }
        }
    }

    &:first-of-type {
        // show only icon-start
        .icon-stop, .icon-end {
            display: none;
        }

        .background-line {
            top: 50%;
            height: 110px;
        }
    }

    &:not(:first-of-type):not(:last-of-type) {
        // show only icon-stop
        .icon-start, .icon-end {
            display: none;
        }
    }

    &:last-of-type {
        // show only icon-end
        .icon-start ,.icon-stop {
            display: none;
        }

        .passenger-wrapper {
            display: none;
        }

        .background-line {
            bottom: 50%;
            height: 110px;
        }
    }

    // line between icons
    .background-line {
        display: block;
        position: absolute;
        width: 2px;
        height: 220px;
        z-index: 0;
        background-color: var(--ion-color-gray-border);
    }

    .destination-wrapper {
        display: flex;
        align-items: center;

        > ion-col {
            padding-top: 0;
            padding-bottom: 0;
        }

        .icon-wrapper, .time-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .icon-wrapper {
            .icon {
                min-width: 17px;
                aspect-ratio: 1/1;
                z-index: 1;
            }
        }

        .destination {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            white-space: normal;
            -webkit-line-clamp: 5;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .passenger-wrapper {
        display: flex;
        margin-top: 10px;

        .count-wrapper, .phone-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .passenger-info {
            display: flex;
            align-items: center;

            .image-wrapper {
                width: 32px;
                height: 32px;
                aspect-ratio: 1/1;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .info-wrapper {
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .info-text {
                    font-size: 13px;

                    white-space: normal;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .passenger-phone {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>