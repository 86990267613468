<template>
    <ion-page class="ion-page page-order-detail">
        <ion-content class="footer-safe-area">
            <div class="container">
                <div class="detail-header">
                    <ion-icon class="back-button" :icon="arrowBackOutline" @click="goBack()"></ion-icon>
                    <PassengersCount :passengers-count="1" />
                    <CarNumber car-number="PP345DF" />
                    <OrderTag />
                    <OrderTag />
                    <OrderTag />
                </div>

                <OrderDateTime />

                <div class="order-travel-plan">
                    <OrderDetailStopCard />
                    <OrderDetailStopCard />
                    <OrderDetailStopCard />
                </div>

                <div class="order-price">
                    <div class="price-info">
                        <p class="info-label">Klient</p>
                        <p class="info-value">Slovnaft</p>
                    </div>
                    <div class="price-info">
                        <p class="info-label">Celková cena</p>
                        <p class="info-value">16,78€</p>
                    </div>
                    <div class="price-info">
                        <p class="info-label">Spôsob platby</p>
                        <div class="info-value type-payment">
                            <span class="label-before">Karta</span>
                            <span class="toggle-wrapper">
                                <ion-toggle mode="ios" class="type-payment-toggle"></ion-toggle>
                            </span>
                            <span class="label-after">€</span>
                        </div>
                    </div>
                </div>
            </div>

            <OrderNote />
            <ClientNote />

            <div class="container">
                <ChatMessages />
            </div>

            <OrderActions />
        </ion-content>
    </ion-page>
</template>

<script>
import OrderActions from "@/components/driver/OrderActions";
import { arrowBackOutline } from "ionicons/icons"
import OrderDetailStopCard from "@/components/driver/OrderDetailStopCard";
import { IonToggle } from "@ionic/vue";
import ChatMessages from "@/components/general/ChatMessages";
import OrderNote from "@/components/general/elements/OrderNote";
import ClientNote from "@/components/general/elements/ClientNote";
import PassengersCount from "@/components/general/elements/PassengersCount";
import CarNumber from "@/components/general/elements/CarNumber";
import OrderTag from "@/components/general/elements/OrderTag";
import OrderDateTime from "@/components/general/elements/OrderDateTime";

export default {
    name: "PageDriverOrderDetail",
    components: {
        OrderDateTime,
        OrderTag,
        CarNumber,
        PassengersCount,
        ClientNote,
        OrderNote,
        ChatMessages,
        IonToggle,
        OrderDetailStopCard,
        OrderActions
    },
    data() {
        return {
            arrowBackOutline,
        }
    },
}
</script>

<style lang="scss" scoped>
.page-order-detail::v-deep {
    .detail-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px 0;

        > div, .back-button {
            margin-top: 10px;
            margin-right: 5px;
        }

        .back-button {
            margin-left: -5px;
        }

        .passengers-count {
            margin-right: 15px;
        }
    }

    .order-price {
        .price-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;

            &:not(:last-of-type) {
                border-bottom: 1px solid var(--ion-color-gray-border);
            }

            .info-value {
                font-weight: 800;
            }

            .type-payment {
                display: flex;
                align-items: center;

                .label-before {
                    margin-right: 15px;
                }

                .toggle-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .type-payment-toggle {
                        position: relative;
                        --background: var(--ion-color-gray-shadow);
                        --background-checked: var(--ion-color-gray-shadow);
                        --handle-background: var(--ion-color-primary);
                        --handle-background-checked: var(--ion-color-primary);
                        --handle-box-shadow: none;

                        --handle-width: 20px;
                        --handle-height: 20px;
                        --handle-spacing: 5px;

                        width: 60px;
                        height: 30px;

                        &::part(track) {
                            //contain: none;
                            //background: white;
                            //box-shadow: 0 0 10px 5px var(--ion-color-gray-shadow);
                        }
                    }
                }

                .label-after {
                    margin-left: 15px;
                }
            }
        }
    }
}
</style>