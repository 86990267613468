<template>
    <div class="container order-card">
        <div class="detail-header">
            <PassengersCount
                :count="2"
                :set-background="true"
            />

            <OrderDate
                :order-date="'12. 02. 2022'"
                :order-time="'12:00'"
            />
        </div>
        <TripStops @open-order-detail="openOrderDetail"/>
    </div>
</template>

<script>
import OrderDate from "@/components/customer/elements/OrderDate";
import PassengersCount from "@/components/general/elements/PassengersCount";
import TripStops from "@/components/general/elements/TripStops";

export default {
    name: "OrderDetail",
    components: {
        PassengersCount,
        TripStops,
        OrderDate,
    },
    methods: {
        openOrderDetail(orderID) {
            this.$router.push({ name: 'PageCustomerOrderDetail', params: { hire_id: orderID } })
        }
    },
}
</script>

<style lang="scss" scoped>
.order-card::v-deep {
    margin: 20px 0 10px;
    z-index: 0;

    .detail-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--ion-color-gray-border);
    }
}
</style>