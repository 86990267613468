<template>
    <ion-page class="ion-page page-customer-order-detail">
        <ion-content>
            <div class="container">
                <div class="detail-header">
                    <ion-icon class="back-button" :icon="arrowBackOutline" @click="goBack()"></ion-icon>
                    <PassengersCount :passengers-count="1" />
                    <CarType :car-type="'Sclass'" />
                    <CarNumber :car-number="'PP345DF'" />
                    <OrderTag />
                    <OrderTag />
                    <OrderTag />
                </div>

                <OrderDateTime />

                <div class="order-travel-plan">
                    <OrderDetailStopCard :allow-call="allowCall" />
                    <OrderDetailStopCard :allow-call="allowCall" />
                    <OrderDetailStopCard :allow-call="allowCall" />
                </div>

                <OrderHistoryInfo />
            </div>

            <OrderActions />

            <ClientNote />

            <OrderDriverName />

            <div class="container">
                <ChatMessages />
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import OrderActions from "@/components/customer/OrderActions";
import ClientNote from "@/components/general/elements/ClientNote";
import OrderDriverName from "@/components/customer/elements/OrderDriverName";
import ChatMessages from "@/components/general/ChatMessages";
import OrderDetailStopCard from "@/components/driver/OrderDetailStopCard";
import OrderDateTime from "@/components/general/elements/OrderDateTime";
import PassengersCount from "@/components/general/elements/PassengersCount";
import CarNumber from "@/components/general/elements/CarNumber";
import OrderTag from "@/components/general/elements/OrderTag";
import { arrowBackOutline } from "ionicons/icons"
import CarType from "@/components/general/elements/CarType";
import OrderHistoryInfo from "@/components/customer/OrderHistoryInfo";

export default {
    name: "PageCustomerOrderDetail",
    components: {
        OrderHistoryInfo,
        CarType,
        OrderTag,
        CarNumber,
        PassengersCount,
        OrderDateTime,
        OrderDetailStopCard,
        ChatMessages,
        OrderDriverName,
        ClientNote,
        OrderActions
    },
    data() {
        return {
            arrowBackOutline,

            allowCall: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.page-customer-order-detail::v-deep {
    .detail-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px 0;

        > div, .back-button {
            margin-top: 10px;
            margin-right: 5px;
        }

        .back-button {
            margin-left: -5px;
        }

        .passengers-count {
            margin-right: 15px;
        }
    }
}
</style>