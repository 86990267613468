<template>
    <ion-page class="ion-page page-registration">
        <ion-content>
            <div class="container">
                <div class="header-wrapper">
                    <ion-icon class="back-button" :icon="arrowBackOutline" @click="goBack()"></ion-icon>
                    <h1 class="title">Registrácia</h1>
                </div>

                <ion-list lines="none" mode="ios" class="main-form-container">
                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Meno a priezvisko:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Adresa:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Mesto:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Telefón:</ion-label>
                        <ion-input type="tel" placeholder="vo formáte +421 9xx xxx xxx" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">
                            Dátum narodenia:
                            <div class="description">Slúži na overenie vášho veku:</div>
                        </ion-label>
                        <ion-input type="date" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-row class="photo-input-wrapper">
                            <ion-col size="6">
                                <ion-label class="label" position="stacked">
                                    Profilová fotografia:
                                    <div class="description">Slúži na overenie vašej totožnosti:</div>
                                </ion-label>
                                <ion-button class="btn btn-outline" @click="openInputFile">
                                    <span class="btn-content">
                                        <span class="btn-content__label">Vybrať</span>
                                        <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                                    </span>
                                </ion-button>
                                <input
                                    type="file"
                                    class="profile-photo-input"
                                    accept=".jpg, .jpeg, .png"
                                    @input="onImageUpload"
                                    ref="registration_photo_input"
                                />
                            </ion-col>
                            <ion-col size="6" class="photo-wrapper">
                                <img
                                    class="photo-preview"
                                    v-if="registrationData.profilePhoto.preview"
                                    :src="registrationData.profilePhoto.preview"
                                    alt=" "
                                />
                            </ion-col>
                        </ion-row>
                    </ion-item>

                    <span class="break-line"></span>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper" lines="none">
                        <ion-label class="label" position="stacked">Zopakujte heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>
                </ion-list>

                <div class="consents-wrapper">
                    <ion-list mode="ios" lines="none" class="full-item-clickable">
                        <ion-item lines="none">
                            <ion-label class="label">Chcem sa registrovať ako firma</ion-label>
                            <ion-checkbox
                                slot="start"
                                class="checkbox"
                                v-model="registerAsCompany"
                                @update:modelValue="checkRegisterAsCompany"
                            ></ion-checkbox>
                        </ion-item>
                    </ion-list>

                    <ion-accordion-group mode="ios">
                        <ion-accordion value="registerCompany">
                            <div slot="content">
                                <ion-list lines="none" class="main-form-container">
                                    <ion-item class="input-wrapper" lines="none">
                                        <ion-label class="label" position="stacked">Názov spoločnosti:</ion-label>
                                        <ion-input type="text" class="input"></ion-input>
                                    </ion-item>

                                    <ion-item class="input-wrapper" lines="none">
                                        <ion-label class="label" position="stacked">IČO:</ion-label>
                                        <ion-input type="text" class="input"></ion-input>
                                    </ion-item>

                                    <ion-item class="input-wrapper" lines="none">
                                        <ion-label class="label" position="stacked">DIČ:</ion-label>
                                        <ion-input type="text" class="input"></ion-input>
                                    </ion-item>
                                </ion-list>
                            </div>
                        </ion-accordion>
                    </ion-accordion-group>

                    <ion-row mode="ios" class="part-item-clickable">
                        <ion-col size="2" class="checkbox-col">
                            <ion-checkbox class="checkbox"></ion-checkbox>
                        </ion-col>
                        <ion-col size="10">
                            Súhlasím so <a href="">spracovaním osobných údajov</a>
                        </ion-col>
                    </ion-row>

                    <ion-row mode="ios" class="part-item-clickable">
                        <ion-col size="2" class="checkbox-col">
                            <ion-checkbox class="checkbox"></ion-checkbox>
                        </ion-col>
                        <ion-col size="10">
                            Súhlasím so <a href="">všeobecnými obchodnými podmienkami služby Carsen CUSTOMER</a>
                        </ion-col>
                    </ion-row>
                </div>

                <ion-button
                    mode="ios"
                    class="btn btn-primary"
                    @click="registerUser"
                    :class="{ 'btn-loading': loaders.registerUser }"
                >
                    <span v-if="!loaders.registerUser" class="btn-content">
                        <span class="btn-content__label">Registrovať sa</span>
                        <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                    </span>
                    <ion-spinner v-else></ion-spinner>
                </ion-button>

            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonList, IonItem, IonLabel, IonCheckbox, IonRow, IonCol, IonAccordionGroup, IonAccordion } from "@ionic/vue"
import { arrowBackOutline, chevronForwardOutline } from "ionicons/icons"

export default {
    name: "PageRegistration",
    components: {
        IonAccordionGroup,
        IonAccordion,
        IonLabel,
        IonRow,
        IonCol,
        IonCheckbox,
        IonList,
        IonItem
    },
    data() {
        return {
            arrowBackOutline, chevronForwardOutline,

            registrationData: {
                profilePhoto: {
                    source: null,
                    preview: null,
                }
            },

            registerAsCompany: false,

            loaders: {
                registerUser: false,
            }
        }
    },
    methods: {
        checkRegisterAsCompany(event) {
            const accordionGroup = document.querySelector('ion-accordion-group');
                if (event) {
                    accordionGroup.value = 'registerCompany';
                } else {
                    accordionGroup.value = undefined;
                }
        },
        openInputFile() {
            this.$refs.registration_photo_input.click()
        },
        onImageUpload(event) {
            this.registrationData.profilePhoto.source = event.target.files[0];
            this.registrationData.profilePhoto.preview = URL.createObjectURL(event.target.files[0]);
        },
        registerUser() {
            this.loaders.registerUser = !this.loaders.registerUser
        }
    },
}
</script>

<style lang="scss" scoped>
.page-registration::v-deep {
    //
}
</style>