<template>
    <div class="order-create-button" @click="redirectToPage('PageHome')">
        <div class="order-create-button-wrapper">
            <p class="create-text">Vytvoriť objednávku</p>
            <img :src="addOrder" alt=" " />
        </div>
    </div>
</template>

<script>
import addOrder from "@/assets/order/addOrder.svg"

export default {
    name: "OrderCreateButton",
    data() {
        return {
            addOrder,
        }
    },
}
</script>

<style lang="scss" scoped>
.order-create-button::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(0, var(--ion-color-primary) 0%, var(--ion-color-primary-light) 100%);
    z-index: 5;

    .order-create-button-wrapper {
        display: flex;
        align-items: center;
        color: white;

        .create-text {
            margin-right: 15px;
            font-size: 20px;
        }
    }
}
</style>