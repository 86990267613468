<template>
    <div class="order-note">
        <div class="note-header">
            <h3 class="note-title">Poznámka office</h3>
            <OrderNoteTag :tag="'VIP'" />
            <OrderNoteTag :tag="'Drink'" />
        </div>
        <p class="note-text">{{ note }}</p>
    </div>
</template>

<script>
import OrderNoteTag from "@/components/general/elements/OrderNoteTag";

export default {
    name: "OrderNote",
    components: {
        OrderNoteTag
    },
    props: {
        /**
         * Note about the client
         */
        note: {
            type: String,
            default: 'Fusce egestas egestas nulla, a hendrerit odio blandit ut. Sed sed pharetra urna, ut finibus mi!'
        }
    }
}
</script>

<style lang="scss" scoped>
.order-note::v-deep {
    padding: 20px 15px;
    background: var(--ion-color-gray-border-light);

    .note-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        > div {
            margin-top: 3px;
            margin-bottom: 3px;
        }

        .note-title {
            padding-right: 20px;
        }

        .hire-note-tag {
            margin-right: 10px;
        }
    }

    .note-text {
        line-height: 24px;
    }
}
</style>