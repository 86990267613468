<template>
    <div class="order-history-info">
        <ion-row class="history-wrapper price">
            <ion-col class="title">Cena</ion-col>
            <ion-col class="value">16,78 €</ion-col>
        </ion-row>

        <ion-row class="history-wrapper dph">
            <ion-col class="title">Dph</ion-col>
            <ion-col class="value">3,40 €</ion-col>
        </ion-row>

        <ion-row class="history-wrapper full-price">
            <ion-col class="title">Celková cena</ion-col>
            <ion-col class="value">20,18 €</ion-col>
        </ion-row>

        <span class="break-line"></span>

        <ion-row class="history-wrapper invoice">
            <ion-col class="title">Faktúra:</ion-col>
            <ion-col class="value">Stiahnuť fakúru</ion-col>
        </ion-row>
    </div>
</template>

<script>
import { IonRow, IonCol } from "@ionic/vue"

export default {
    name: "OrderHistoryInfo",
    components: {
        IonRow,
        IonCol,
    }
}
</script>

<style lang="scss" scoped>
.order-history-info::v-deep {
    margin: 20px 0;

    .history-wrapper {
        .value {
            text-align: right;
        }

        &.price {
            .value {
                font-weight: bold;
            }
        }

        &.dph {
            color: var(--ion-color-gray-dark);
        }

        &.full-price {
            font-size: 18px;
            font-weight: bold;
        }

        &.invoice {
            .value {
                color: var(--ion-color-primary);
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}
</style>