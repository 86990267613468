<template>
    <div class="order-tag">
        <div :id="tagId" class="tag-wrapper">
            <img class="icon" :src="repeat" alt=" " />
        </div>
        <ion-popover mode="ios" :trigger="tagId" trigger-action="click">
            <ion-content class="ion-padding">Hello World!</ion-content>
        </ion-popover>
    </div>
</template>

<script>
import { IonPopover } from "@ionic/vue"
import repeat from "@/assets/icons/orderTags/repeat.svg"
import ticket from "@/assets/icons/orderTags/ticket.svg"
import child from "@/assets/icons/orderTags/child.svg"

export default {
    name: "OrderTag",
    components: {
        IonPopover
    },
    props: {
        /**
         * Tag data - contains icon type, popover data...
         */
        tagData: {
            type: Object,
            default() {
                return {}
            }
        },
        /**
         * Unique id for tag - for popover
         * @example {string} '38cf37a1-2825-4aca-a7ba-d269f0ffb2f2'
         */
        tagId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            repeat, ticket, child,
        }
    },
}
</script>

<style lang="scss" scoped>
.order-tag::v-deep {
    .tag-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        aspect-ratio: 1/1;
        background: white;
        border-radius: 50%;
        box-shadow: 0 2px 3px var(--ion-color-gray-shadow-dark);
    }
}
</style>