<template>
    <ion-app>
        <ion-split-pane content-id="main-content">
            <ion-router-outlet id="main-content"></ion-router-outlet>
            <MainMenu contentId="main-content" />
        </ion-split-pane>
    </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import MainMenu from "@/components/main/MainMenu";

export default defineComponent({
    name: 'App',
    components: {
        MainMenu,
        IonApp,
        IonRouterOutlet,
        IonSplitPane,
    },
});
</script>

<style lang="scss" scoped>
</style>
