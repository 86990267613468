<template>
    <div class="order-actions">
        <div class="action-item-wrapper">
            <div class="action-item action-item-edit">
                <img class="icon" :src="edit" alt=" " v-svg-inline />
            <p>Editovať zakázku</p>
            </div>
        </div>
        <div class="action-item-wrapper">
            <div class="action-item action-item-cancel">
                <img class="icon" :src="trash" alt=" " v-svg-inline />
            <p>Zrušiť</p>
            </div>
        </div>
    </div>
</template>

<script>
import trash from "@/assets/icons/trash.svg"
import edit from "@/assets/icons/edit.svg"

export default {
    name: "OrderHandler",
    data() {
        return {
            trash, edit,
        }
    },
}
</script>

<style lang="scss" scoped>
.order-actions::v-deep {
    display: flex;
    align-items: center;
    height: 60px;
    background: white;

    .action-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 50%;
        border: 1px solid var(--ion-color-gray-border);
    }

    .action-item {
        display: flex;
        align-items: center;
        color: black;

        .icon {
            margin-right: 5px;
        }

        .icon * {
            fill: black;
        }

        &-edit {
            //
        }

        &-cancel {
            //
        }
    }
}
</style>