<template>
    <div class="client-note">
        <h3 class="note-title">Poznámka klient</h3>
        <p class="note-text">{{ note }}</p>
    </div>
</template>

<script>
export default {
    name: "ClientNote",
    props: {
        /**
         * Note from the client
         */
        note: {
            type: String,
            default: 'Etiam id odio luctus, ultrices lacus et, consectetur nunc. Etiam in est ut risus facilisis convallis. Ut efficitur risus nibh, ut fermentum sem.'
        },
    },
}
</script>

<style lang="scss" scoped>
.client-note::v-deep {
    padding: 20px;
    background: rgba(var(--ion-color-primary-bright-rgb), 0.5);

    .note-title {
        margin-bottom: 15px;
    }

    .note-text {
        line-height: 24px;
    }
}
</style>