<template>
    <div class="main-header">
        <img class="logo" :src="carsenLogo" alt=" " />

        <div class="menu-button" @click="menuButton">
            <ion-icon
                class="icon"
                :icon="menuIconType.icon"
                :class="menuIconType.iconClass"
            ></ion-icon>
        </div>
    </div>
</template>

<script>
import carsenLogo from "@/assets/carsenLogo.svg"
import {menuController} from "@ionic/vue";
import { menu, close } from "ionicons/icons";

export default {
    name: "MainHeader",
    props: {
        /**
         * Type of the button
         * @values {string} menu - show menu icon
         * @values {string} close - show close icon
         */
        btn_type: {
            type: String,
            default: 'menu'
        },
    },
    data() {
        return {
            carsenLogo, menu, close,
        }
    },
    methods: {
        menuButton() {
            this.btn_type === 'menu'
                ? menuController.open()
                : menuController.close()
        }
    },
    computed: {
        menuIconType() {
            let menuIcon = {
                icon: null,
                iconClass: ''
            }
            switch (this.btn_type) {
                case 'close':
                    menuIcon.icon = this.close
                    menuIcon.iconClass = 'icon-close'
                    break;
                default:
                    menuIcon.icon = this.menu
                    menuIcon.iconClass = 'icon-menu'
            }
            return menuIcon
        }
    }
}
</script>

<style lang="scss" scoped>
.main-header::v-deep {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-top: 20px;

    .logo {
        width: 150px;
    }

    .menu-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: white;
        border-radius: 50%;
        box-shadow: 0 15px 30px var(--ion-color-gray-shadow);

        .icon {
            width: 25px;
            height: 25px;
            color: var(--ion-color-primary);
        }
    }

}
</style>