<template>
    <div class="car-type">
        {{ carType }}
    </div>
</template>

<script>
export default {
    name: "CarType",
    props: {
        /**
         * Type of car
         * @example 'octavia'
         */
        carType: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.car-type::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-width: 60px;
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    box-shadow: 0 6px 12px var(--ion-color-gray-shadow);
}
</style>