import { toastController } from "@ionic/vue";
import { close } from "ionicons/icons";

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                /**
                 * Redirect to page
                 * @param {string} pageName - the name of the page to which the redirect should take place
                 */
                redirectToPage(pageName) {
                    this.$router.push({ name: pageName })
                },
                /**
                 * Redirect go back
                 */
                goBack() {
                    this.$router.back()
                },
                /**
                 * @desc Open Ion Toast
                 * @param {string} toastClass - toast class, values: toast-success / toast-danger
                 * @param {string} toastText - text to display in toast
                 * @param {number} toastShowDuration - toaster display time - time is given in milliseconds
                 */
                async showToast(toastText, toastClass = 'toast-success', toastShowDuration = 3000) {
                    const toast = await toastController
                        .create({
                            duration: toastShowDuration,
                            position: 'top',
                            message: toastText,
                            cssClass: toastClass,
                            buttons: [{
                                icon: close,
                                role: 'cancel',
                            }]
                        })
                    return toast.present();
                },
            },
            computed: {
                /**
                 * Date formatter
                 * @property {date} date - the date to format
                 * @property {string} outputFormat - formatting type, default: 'DD.MM.YYYY', example: 'DD-MM-YYYY'
                 * @property {string} inputFormat - input date format
                 * @return {(function(*=, *=, *=): (*))|*}
                 */
                formatDate() {
                    return (date, outputFormat = 'DD.MM.YYYY', inputFormat) => {
                        if (inputFormat) {
                            return this.$moment(date, inputFormat).format(outputFormat)
                        } else {
                            return this.$moment(date).format(outputFormat)
                        }
                    }
                }
            }
        })
    }
}