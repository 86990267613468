<template>
    <div class="order-date">
        <div class="order-date-wrapper">
            <p class="date">{{ orderDate }}</p>
            <p class="time">{{ orderTime }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderDate",
    props: {
        /**
         * Date of order
         * @example '23.06.2022'
         */
        orderDate: {
            type: String,
            default: ''
        },
        /**
         * Time of order
         * @example '8:00'
         */
        orderTime: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.order-date::v-deep {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    min-width: 138px;
    height: 26px;
    border-radius: 8px;
    background: var(--ion-color-gray-content);
    box-shadow: 0 4px 8px var(--ion-color-gray-shadow);

    .order-date-wrapper {
        display: flex;
        align-items: center;

        .date {
            font-weight: 600;
            margin-right: 10px;
        }

        .time {
            //
        }
    }
}
</style>