import { createRouter, createWebHistory } from '@ionic/vue-router';

// SECTION - GENERAL
import PageHome from "@/views/general/PageHome.vue"

// login
import PageLogin from "@/views/general/login/PageLogin.vue"
import PageRegistration from "@/views/general/login/PageRegistration.vue"

// SECTION - CREATE ORDER
import PageOrderWayThere from "@/views/order/PageOrderWayThere.vue"

// SECTION - CUSTOMER
import PageCustomerDashboard from "@/views/customer/PageCustomerDashboard.vue"
import PageCustomerOrderDetail from "@/views/customer/PageCustomerOrderDetail.vue"
import PageCustomerProfile from "@/views/customer/PageCustomerProfile.vue"

// SECTION - DRIVER
import PageDriverDashboard from "@/views/driver/PageDriverDashboard.vue"
import PageDriverOrderDetail from "@/views/driver/PageDriverOrderDetail.vue"

const routes = [
    {
        path: '/',
        redirect: '/home',
    },

    // SECTION - CREATE ORDER
    {
        path: '/order/way-there',
        name: 'PageOrderWayThere',
        component: PageOrderWayThere,
    },

    // SECTION - GENERAL
    {
        path: '/home',
        name: 'PageHome',
        component: PageHome,
    },
    {
        path: '/login',
        name: 'PageLogin',
        component: PageLogin,
    },
    {
        path: '/registration',
        name: 'PageRegistration',
        component: PageRegistration,
    },

    // SECTION - CUSTOMER
    {
        path: '/customer/dashboard',
        name: 'PageCustomerDashboard',
        component: PageCustomerDashboard,
    },
    {
        path: '/customer/order/:hire_id',
        name: 'PageCustomerOrderDetail',
        component: PageCustomerOrderDetail,
    },
    {
        path: '/customer/profile',
        name: 'PageCustomerProfile',
        component: PageCustomerProfile,
    },

    // SECTION - DRIVER
    {
        path: '/driver/dashboard',
        name: 'PageDriverDashboard',
        component: PageDriverDashboard,
    },
    {
        path: '/driver/order/:hire_id',
        name: 'PageDriverOrderDetail',
        component: PageDriverOrderDetail,
    },

    // {
    //   path: '/folder/:id',
    //   component: () => import ('../views/FolderPage.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach(async (to, from, next) => {
//     console.log(to, from)
//     next()
// })

export default router
