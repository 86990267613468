<template>
    <div class="order-list">
        <div
            class="order-container"
            v-for="(orders, ordersKey, ordersIndex) in testData" :key="ordersIndex"
        >
            <div class="order-title-wrapper">
                <p class="order-title">{{ formatDate(ordersKey, 'dddd', 'DD-MM-YYYY') }}</p>
                <p class="order-date">{{ formatDate(ordersKey, 'DD.MM.YYYY', 'DD-MM-YYYY') }}</p>
            </div>
            <OrderListCard
                v-for="(order, orderKey, orderIndex) in orders" :key="orderIndex"
                :order-key="orderKey"
                :order="order"
                :class="{ 'next-order': !ordersIndex && !orderIndex }"
            />
        </div>
    </div>
</template>

<script>
import OrderListCard from "@/components/driver/OrderListCard";

export default {
    name: "OrderList",
    components: {
        OrderListCard
    },
    data() {
        return {
            testData: {
                '18-07-2022': {
                    'order11': {
                        passengers: 4,
                        trip: {
                            from: 'Letisko Viedeň-Schwechat',
                            to: 'Ružová dolina 29, Bratislava',
                            numberOfStops: 3,
                        },
                        carNumber: 'BT-587DY',
                        tags: {
                            1: 'baby',
                            2: 'ticket',
                            3: 'two-way',
                        },
                        user: {
                            id: 1,
                            name: 'Alojz Novák',
                            telNumber: '0987123123',
                            company: 'Alojz Novák s.r.o.',
                            image: 'https://images.pexels.com/photos/47547/squirrel-animal-cute-rodents-47547.jpeg?cs=srgb&dl=pexels-pixabay-47547.jpg&fm=jpg',
                        }
                    },
                    'order12': {
                        passengers: 4,
                        trip: {
                            from: 'Letisko Viedeň-Schwechat',
                            numberOfStops: 3,
                            to: 'Ružová dolina 29, Bratislava',
                        },
                        carNumber: 'BT-587DY',
                        tags: {
                            1: 'baby',
                            2: 'ticket',
                            3: 'two-way',
                        },
                        user: {
                            id: 1,
                            name: 'Alojz Novák',
                            telNumber: '0987123123',
                            company: 'Alojz Novák s.r.o.',
                            image: 'https://images.pexels.com/photos/47547/squirrel-animal-cute-rodents-47547.jpeg?cs=srgb&dl=pexels-pixabay-47547.jpg&fm=jpg',
                        }
                    },
                    'order13': {
                        passengers: 4,
                        trip: {
                            from: 'Letisko Viedeň-Schwechat',
                            to: 'Ružová dolina 29, Bratislava',
                            numberOfStops: 3,
                        },
                        carNumber: 'BT-587DY',
                        tags: {
                            1: 'baby',
                            2: 'ticket',
                            3: 'two-way',
                        },
                        user: {
                            id: 1,
                            name: 'Alojz Novák',
                            telNumber: '0987123123',
                            company: 'Alojz Novák s.r.o.',
                            image: 'https://images.pexels.com/photos/47547/squirrel-animal-cute-rodents-47547.jpeg?cs=srgb&dl=pexels-pixabay-47547.jpg&fm=jpg',
                        }
                    },
                },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.order-list::v-deep {
    .order-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;
        color: white;
        background: var(--ion-color-primary);

        .order-title {
            font-weight: 200;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .order-date {
            //
        }
    }

    .next-order {
        background: var(--ion-color-primary-bright);
    }
}
</style>