<template>
    <ion-page class="ion-page page-home">
        <ion-content>
            <div class="container content-wrapper">
                <div class="carsen-logo-wrapper">
                    <img :src="carsenLogo" alt=" " />
                </div>

                <div class="select-type-container">
                    <ion-button
                        class="btn btn-primary btn-order"
                        @click="redirectToPage('')"
                    >
                        <span class="btn-content">
                            <span class="btn-content__label">Objednať bez registrácie</span>
                            <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                        </span>
                    </ion-button>

                    <ion-button
                        class="btn btn-outline btn-login"
                        @click="redirectToPage('PageLogin')"
                    >
                        <span class="btn-content">
                            <span class="btn-content__label">Prihlásiť sa</span>
                            <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                        </span>
                    </ion-button>

                    <ion-button
                        class="btn btn-primary btn-registration"
                        @click="redirectToPage('PageRegistration')"
                    >
                        <span class="btn-content">
                            <span class="btn-content__label">Registrácia</span>
                            <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                        </span>
                    </ion-button>
                </div>

                <div class="wame-logo-wrapper">
                    <img :src="wameLogo" alt=" " />
                </div>
            </div>

        </ion-content>
    </ion-page>
</template>

<script>
import { chevronForwardOutline } from "ionicons/icons"
import carsenLogo from "@/assets/carsenLogo.svg"
import wameLogo from "@/assets/wameLogo.svg"

export default {
    name: "PageHome",
    data() {
        return {
            carsenLogo, wameLogo,
            chevronForwardOutline,
        }
    },
}
</script>

<style lang="scss" scoped>
.page-home::v-deep {
    ion-content {
        --padding-bottom: 0;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        z-index: 1;
    }

    .carsen-logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .select-type-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .btn-order {
            height: 80px;
        }

        .btn-login {
            margin-top: 5vh;
        }

        .btn-registration {
            margin-top: 2vh;
        }

        .btn-login, .btn-registration {
            max-width: 220px;
        }
    }

    .wame-logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 360px) {
        .select-type-container {
            .btn-order {
                height: 60px;
                font-size: 16px;
            }

            .btn-login, .btn-registration {
                transform: scale(0.8);
            }

            .btn-registration {
                margin-top: 0;
            }
        }
    }

    @media (max-height: 400px) {
        .select-type-container {
            .btn-order {
                height: 60px;
                font-size: 16px;
            }

            .btn-login, .btn-registration {
                transform: scale(0.8);
            }

            .btn-registration {
                margin-top: 0;
            }
        }
    }
}
</style>