<template>
    <ion-page class="ion-page page-login">
        <ion-content>
            <div class="container">
                <div class="header-wrapper">
                    <ion-icon class="back-button" :icon="arrowBackOutline" @click="goBack()"></ion-icon>
                    <h1 class="title">Prihlásenie</h1>
                </div>

                <ion-list lines="none" mode="ios" class="main-form-container">
                    <ion-item class="input-wrapper">
                        <ion-label class="label" position="stacked">Prihlasovacie meno:</ion-label>
                        <ion-input type="text" class="input"></ion-input>
                    </ion-item>

                    <ion-item class="input-wrapper">
                        <ion-label class="label" position="stacked">Heslo:</ion-label>
                        <ion-input type="password" class="input"></ion-input>
                    </ion-item>
                </ion-list>

                <ion-button
                    mode="ios"
                    class="btn btn-primary"
                    @click="loginUser"
                    :class="{ 'btn-loading': loaders.postLogin }"
                >
                    <span v-if="!loaders.postLogin" class="btn-content">
                        <span class="btn-content__label">Prihlásiť</span>
                        <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                    </span>
                    <ion-spinner v-else></ion-spinner>
                </ion-button>

                <p class="forgot-password">
                    Zabudnuté heslo?
                </p>

                <div class="wame-logo-wrapper">
                    <img class="wame-logo" :src="wameLogo" alt=" " />
                </div>
            </div>

        </ion-content>
    </ion-page>
</template>

<script>
import { IonList, IonItem, IonLabel } from "@ionic/vue"
import { arrowBackOutline, chevronForwardOutline } from "ionicons/icons"
import wameLogo from "@/assets/wameLogo.svg"

export default {
    name: "PageLogin",
    components: {
        IonList,
        IonItem,
        IonLabel
    },
    data() {
        return {
            wameLogo,
            arrowBackOutline, chevronForwardOutline,

            loaders: {
                postLogin: false,
            }
        }
    },
    methods: {
        loginUser() {
            this.loaders.postLogin = !this.loaders.postLogin
        }
    },
}
</script>

<style lang="scss" scoped>
.page-login::v-deep {
    .main-form-container {
        margin-top: 15vh;
    }

    .forgot-password {
        margin-top: 5vh;
        text-align: center;
    }

    .wame-logo-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 15vh;
        padding: 20px 0;

        .wame-logo {
            //
        }
    }


    @media (max-height: 650px) {
        .main-form-container {
            margin-top: 0;
        }

        .wame-logo-wrapper {
            margin-top: 0;
        }
    }
}
</style>