import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/fonts.scss';
import './theme/variables.scss';
import './theme/global.scss';

/* Global Functions */
import globalFunctions from "./functions/global.js"

/* import Vue Svg Inline Plugin */
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(VueSvgInlinePlugin)
    .use(globalFunctions)
    .use(VueAxios, axios);

/* Moment.js */
import moment from "moment-timezone";
import skLocale from "moment/locale/sk";
skLocale.relativeTime = {
    future: 'Za %s',
    past: 'Pred %s',
    s: '%ds',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    w: '%dt',
    ww: '%dt',
    M: '%dm',
    MM: '%dm',
    y: '%dr',
    yy: '%dr',
}
moment.updateLocale('sk', skLocale)
app.config.globalProperties.$moment = moment;

/* Ion Components */
import { IonButton, IonContent, IonIcon, IonInput, IonPage, IonSpinner } from '@ionic/vue';
app.component('ion-button', IonButton);
app.component('ion-content', IonContent);
app.component('ion-icon', IonIcon);
app.component('ion-input', IonInput);
app.component('ion-page', IonPage);
app.component('ion-spinner', IonSpinner);

router.isReady().then(() => {
    app.mount('#app');
});