<template>
    <div class="passengers-count" :class="{ 'add-background': setBackground }">
        <p class="count">{{ count }}</p>
        <img :src="passenger" alt=" " />
    </div>
</template>

<script>
import passenger from "@/assets/icons/passenger.svg"

export default {
    name: "PassengersCount",
    props: {
        /**
         * Add additive class for set background
         * @example true - added class
         * @example false - no added class
         */
        setBackground: {
            type: Boolean,
            default: false
        },
        /**
         * Count of passengers
         */
        count: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            passenger,
        }
    },
}
</script>

<style lang="scss" scoped>
.passengers-count::v-deep {
    display: flex;
    align-items: center;
    justify-content: center;

    &.add-background {
        padding: 0 5px;
        min-width: 36px;
        height: 26px;
        border-radius: 8px;
        background: var(--ion-color-gray-content);
        box-shadow: 0 4px 8px var(--ion-color-gray-shadow);
    }

    .count {
        font-weight: 600;
        margin-right: 4px;
    }
}
</style>