<template>
    <ion-footer class="order-actions" collapse="fade">
        <div
            class="footer-button-wrapper"
            v-for="(button, index) in footerButtons" :key="index"
            @click="handleAction(button.type)"
        >
            <div class="footer-button">
                <img class="icon" :src="button.icon" alt=" " />
                <p class="button-text">{{ button.text }}</p>
            </div>
        </div>
    </ion-footer>
</template>

<script>
import { IonFooter} from '@ionic/vue';
import accept from "@/assets/footer/accept.svg"
import arrowDown from "@/assets/footer/arrowDown.svg"
import arrowUp from "@/assets/footer/arrowUp.svg"
import confirm from "@/assets/footer/confirm.svg"

export default {
    name: "OrderActions",
    components: {
        IonFooter,
    },
    data() {
        return {
            footerButtons: [
                { type: 'accept', text: 'Prijatá', icon: accept },
                { type: 'start', text: 'K nástupu', icon: arrowDown },
                { type: 'exit', text: 'K výstupu', icon: arrowUp },
                { type: 'confirm', text: 'OK', icon: confirm },
            ]
        }
    },
    methods: {
        handleAction(actionType) {
            console.log(actionType)
        }
    },
}
</script>

<style lang="scss" scoped>
.order-actions::v-deep {
    position: fixed;
    bottom: 0;
    display: flex;
    height: 70px;
    background: linear-gradient(0, var(--ion-color-primary) 0%, var(--ion-color-primary-light) 100%);

    .footer-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;

        &:first-of-type {
            background: var(--ion-color-gray-contrast);
        }

        .footer-button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .button-text {
                color: white;
                margin-top: 5px;
                font-size: 13px;
            }
        }
    }
}
</style>