<template>
    <ion-page class="ion-page page-customer-dashboard">
        <ion-content class="footer-safe-area">

            <div class="container dashboard-header-wrapper">
                <MainHeader />

                <h2 class="ion-text-center">Plánované objednávky</h2>

                <SearchInput @change-input="getSearchedOrders" />
            </div>

            <div class="customer-dashboard-content">
                <OrderCard
                    v-for="item in 10" :key="item"
                />
            </div>

            <OrderCreateButton />
        </ion-content>
    </ion-page>
</template>

<script>
import OrderCreateButton from "@/components/customer/OrderCreateButton";
import MainHeader from "@/components/main/MainHeader";
import SearchInput from "@/components/customer/SearchInput";
import OrderCard from "@/components/customer/OrderCard";
import debounce from "lodash.debounce";

export default {
    name: "PageCustomerDashboard",
    components: {
        OrderCard,
        MainHeader,
        SearchInput,
        OrderCreateButton,
    },
    methods: {
        getSearchedOrders: debounce((searchTerm) => {
            console.log(searchTerm)
        }, 500),
    },
}
</script>

<style lang="scss" scoped>
.page-customer-dashboard::v-deep {
    .dashboard-header-wrapper {
        position: fixed;
        width: 100%;
        background: var(--ion-color-gray-content);
        border-bottom: 1px solid var(--ion-color-gray-border);
        z-index: 5;
    }

    .customer-dashboard-content {
        margin-top: 220px;
        z-index: 0;
    }
}
</style>