<template>
    <div class="order-date-time">
        <p class="day-name">{{ formatDate(Date.now(), 'dddd') }}</p>
        <p class="day-date">{{ formatDate(Date.now(), 'DD.MM.YYYY') }}</p>
    </div>
</template>

<script>
export default {
    name: "OrderDateTime",
}
</script>

<style lang="scss" scoped>
.order-date-time::v-deep {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .day-name, .day-date {
        font-size: 18px;
    }

    .day-name::first-letter {
        text-transform: uppercase;
    }

    .day-date  {
        font-weight: 600;
    }
}
</style>