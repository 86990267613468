<template>
    <div class="order-time">
        {{ time }}
    </div>
</template>

<script>
export default {
    name: "OrderTime",
    props: {
        /**
         * Time of order
         */
        time: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.order-time::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-width: 68px;
    height: 28px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    background: var(--ion-color-gray-contrast);
    border-radius: 3px;
}
</style>