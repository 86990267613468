<template>
    <ion-menu type="overlay" class="main-menu" :swipeGesture="false">
        <ion-content>
            <div class="container content-wrapper">
                <MainHeader btn_type="close" />

                <div class="customer-menu">
                    <ion-button
                        mode="ios"
                        class="btn btn-primary"
                    >
                        <span class="btn-content">
                            <span class="btn-content__label">Zadaj objednávku</span>
                            <ion-icon class="btn-content__icon" :icon="chevronForwardOutline"></ion-icon>
                        </span>
                    </ion-button>

                    <ion-list class="menu-items-wrapper">
                        <ion-menu-toggle
                            class="menu-item"
                            v-for="(menuItem, index) in customerMenuItems" :key="index"
                            @click="redirectToPage(menuItem.redirectPage)"
                        >
                            <ion-label>{{ menuItem.label }}</ion-label>
                        </ion-menu-toggle>
                    </ion-list>
                </div>

                <span class="break-line"></span>

                <div class="app-info-wrapper">
                    <ion-menu-toggle @click="logoutUser" class="info-item logout-user">
                        <p>Odhlásiť</p>
                        <img class="logout-icon" :src="logout" alt=" " />
                    </ion-menu-toggle>
                    <p class="info-item app-version">Verzia 1.0a 2021</p>
                    <img class="info-item wame-logo" :src="wameLogo" alt=" " />
                </div>

            </div>
        </ion-content>
    </ion-menu>
</template>

<script>
import { IonMenu, IonList, IonMenuToggle, IonLabel } from '@ionic/vue';
import MainHeader from "@/components/main/MainHeader";
import { chevronForwardOutline } from "ionicons/icons"
import logout from "@/assets/icons/logout.svg"
import wameLogo from "@/assets/wameLogo.svg"

export default {
    name: "MainMenu",
    components: {
        MainHeader,
        IonMenu,
        IonList,
        IonMenuToggle,
        IonLabel,
    },
    data() {
        return {
            logout, wameLogo,
            chevronForwardOutline,

            customerMenuItems: [
                { label: 'Plánované objednávky' , redirectPage: '' },
                { label: 'História objednávok' , redirectPage: '' },
                { label: 'Nastavenie profil' , redirectPage: 'PageCustomerProfile' },
                { label: 'Hotline' , redirectPage: '' },
            ],
        }
    },
    methods: {
        logoutUser() {
            this.$router.push({ name: 'PageHome' })
        }
    },
}
</script>

<style lang="scss" scoped>
.main-menu::v-deep {
    &::part(container) {
        width: 100%;
        max-width: unset;
    }

    .content-wrapper {
        height: 100%;

        .main-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 10vh;
        }
    }

    .customer-menu {
        .menu-items-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: transparent;
        }

        .menu-item {
            margin: 2vh 0;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background: transparent;
        }
    }

    .break-line {
        margin: 7vh auto;
        max-width: 200px;
    }

    .app-info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .info-item {
            margin: 0.7vh 0;
        }

        .logout-user {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;

            .logout-icon {
                margin-left: 15px;
            }
        }

        .app-version {
            font-size: 13px;
            font-weight: bold;
            color: var(--ion-color-gray);
        }

        .wame-logo {
            width: 120px;
        }
    }
}
</style>