<template>
    <div class="order-driver-name">
        <p class="driver-info">Váš šofér</p>
        <img
            class="driver-photo"
            src="https://d5nunyagcicgy.cloudfront.net/external_assets/hero_examples/hair_beach_v391182663/original.jpeg"
            alt=" "
        />
        <p class="driver-info">Ivanka Maslenecká</p>
    </div>
</template>

<script>
export default {
    name: "OrderDriverName",
}
</script>

<style lang="scss" scoped>
.order-driver-name::v-deep {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px 20px;
    background: var(--ion-color-gray-content);

    .driver-info {
        font-weight: bold;
    }

    .driver-photo {
        margin: 0 20px;
        width: 55px;
        height: 55px;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 4px;
        box-shadow: 0 6px 12px var(--ion-color-gray-shadow);
    }
}
</style>