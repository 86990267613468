<template>
    <div class="chat-message-driver" :class="messageTypeClass">
        <div class="message-author">
            <p class="name">Moje meno</p>
            <p class="time">16:00</p>
        </div>
        <p class="message-text">{{ testText }}</p>
    </div>
</template>

<script>
export default {
    name: "ChatMessageDriver",
    props: {
        /**
         * Author message type
         * @value driver/customer
         */
        messageType: {
            type: String,
            default: 'customer'
        },
    },
    data() {
        return {
            testText: 'Powder bonbon tootsie roll pastry sugar plum caramels. Powder cupcakcakujubes ty beans apple pie oissant halvah wafer.'
        }
    },
    computed: {
        messageTypeClass() {
            if (this.messageType === 'driver') {
                return 'message-type-driver'
            } else {
                return 'message-type-customer'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.chat-message-driver::v-deep {
    margin: 20px 0;
    padding: 10px 15px;
    border-radius: 11px;
    box-shadow: 0 3px 6px var(--ion-color-gray-shadow);

    &.message-type-driver {
        border: 1px solid var(--ion-color-gray-border);
    }

    &.message-type-customer {
        margin-left: 40px;
        background: rgba(var(--ion-color-primary-bright-rgb), 0.2);
        border: 1px solid var(--ion-color-primary-bright);
    }

    .message-author {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .name, .time {
            font-size: 13px;
            font-weight: 600;
        }

        .name {
            margin-right: 5px;
            color: var(--ion-color-primary);
        }
    }

    .message-text {
        line-height: 24px;
    }
}
</style>