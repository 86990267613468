<template>
    <div class="hire-note-tag">
        <h4>{{ tag }}</h4>
    </div>
</template>

<script>
export default {
    name: "OrderNoteTag",
    props: {
        /**
         * Tag name
         */
        tag: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.hire-note-tag::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 21px;
    border-radius: 13px;
    background: white;
    //background: var(--ion-color-gray-content);
}
</style>